.title {
    font-family: CormorantUnicase Bold;
    font-size: 23px;
    line-height: 1.2;
    color: var(--primaryTextColor);
    text-shadow: var(--primaryTextShadowColor);
    margin-bottom: 10px;
}

.titleSmall {
    font-family: CormorantUnicase Regular;
    font-size: 16px;
    line-height: 1.3;
    color: var(--primaryTextColor);
    text-shadow: var(--primaryTextShadowColor);
    margin-bottom: 10px;
}

.titleMedium {
    font-family: CormorantUnicase Regular;
    font-size: 20px;
    line-height: 1.3;
    color: var(--primaryTextColor);
    text-shadow: var(--primaryTextShadowColor);
    margin-bottom: 10px;
}

.description {
    font-family: CormorantUnicase Regular;
    font-size: 16px;
    line-height: 1.3;
    color: var(--secondaryTextColor);
    text-shadow: var(--primaryTextShadowColor);
    margin-bottom: 10px;

    @media screen and (min-width: 768px) {
        line-height: 1.5;
    }
}

.links {
    font-family: CormorantUnicase Regular;
    font-size: 16px;
    line-height: 1.3;
    color: var(--hoverColor);
    text-shadow: var(--primaryTextShadowColor);
    border-bottom: 1px solid var(--hoverColor);
    margin-bottom: 10px;

    @media screen and (min-width: 768px) {
        line-height: 1.5;
    }
}

.blockquote {
    font-family: CormorantUnicase Regular;
    font-size: 16px;
    line-height: 1.3;
    padding: 10px 25px;
    margin-left: 15px;
    margin-right: 15px;
    color: var(--hoverColor);
    background-color: rgba(181, 155, 77, 0.05);
    text-shadow: 0px 0px 1px var(--hoverColor);
    border-left: 3px solid var(--hoverColor);
    
    @include mq(tab) {
        line-height: 1.5;
    }
}

.list li {
    margin-left: 30px;
    list-style: disc;
}


.span {
    font-family: CormorantUnicase Bold;
    font-size: 16px;
    line-height: 1.63;
    color: var(--hoverColor);
    text-shadow: var(--primaryTextShadowColor);
    margin-bottom: 10px;
}

