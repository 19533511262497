@font-face {
  font-family: 'CormorantUnicase Regular';
  src: url('../../fonts/CormorantUnicase-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'CormorantUnicase Medium';
  src: url('../../fonts/CormorantUnicase-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'CormorantUnicase SemiBold';
  src: url('../../fonts/CormorantUnicase-SemiBold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: 'CormorantUnicase Bold';
  src: url('../../fonts/CormorantUnicase-Bold.ttf');
  font-weight: 700;
}