@import 'modern-normalize/modern-normalize.css';

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
}

input {
  outline: none;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

button {
  cursor: pointer;
  padding: 0;
}

input {
  padding: 0;
}

.section {
  padding-top: 20px;
  padding-bottom: 40px;

  @include mq(desk) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
